import React, { useState, createContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';

const CredentialsContext = createContext();

function CredentialsProvider({ children }) {
  const [credentials, setCredentials] = useState({});
  const [loading, setLoading] = useState(false);
  const [userSession, setUserSession] = useState(null);

  const values = useMemo(
    () => ({
      userSession,
      credentials,
      setCredentials,
      loading,
      setLoading
    }),
    [userSession, credentials, loading]
  );

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const _userSession = await Auth.currentSession();
        setUserSession(_userSession);
      } catch {
        setUserSession(null);
      }
    };
    fetchSession();
  }, []);

  return (
    <CredentialsContext.Provider value={values}>
      {children}
    </CredentialsContext.Provider>
  );
}

CredentialsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { CredentialsContext };
export default CredentialsProvider;
