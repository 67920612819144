import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { colors } from '@loggi/mar';
import {
  Typography,
  Box,
  List,
  Divider,
  ListItem,
  ListItemText,
  Tooltip,
  Grid,
  Collapse,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
  Avatar,
  Chip
} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { dateFormatter } from 'shared/formatters/date';
import StatusColorDescription from 'shared/status-color-description';
import MissedEventsBox from 'shared/missed-events-box';
import {
  Can,
  CanFunction,
  IsFSActiveForCurrentBase
} from 'auth/access-control';
import { ACTIONS } from 'auth/access-control/access-control.rules';
import formatPhone from 'shared/formatters/phone/phone';
import { KEY_CUSTODY } from 'tracking/tracking.constants';
import { getCompanyType } from 'auth/access-control/access-control.service';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { DisputeDetail } from 'tracking/disputes/disputes-detail.component';
import { SWITCHES } from 'view/constants';
import { DEDUCTIONS_TYPES } from 'finance/finance.constants';
import { getProcessDestinationOrContext } from './drawer-detail.configuration';
import { ReactComponent as InsightIcon } from './insight-02.svg';

import {
  TEXT,
  unitLoadMapper,
  mountDestinationInfo,
  WHERE_IS_THE_PACKAGE,
  PACKAGE_STATUS_CODE,
  PACKAGE_STATUS_CODE_UNAVAILABLE_TO_CHANGE,
  formatPostalCode,
  mountAddressInfo,
  PACKAGE_DIRECTION_RETURN,
  HIERARCHICAL_FALLBACK_LEVEL_INVALID,
  HIERARCHICAL_FALLBACK_LEVEL_UNUSED,
  statusCodeDescription
} from '../drawer.constants';
import useStyles from '../drawer.style';
import {
  ChunkHeaderPackageComponent,
  ChunkHeaderPackageValoresComponent
} from './components';

const LoggiAddressInfo = ({ postalAddress }) => {
  const classes = useStyles();

  return (
    <Box my={1} data-testid="loggi-address-info">
      {postalAddress.addressLines && (
        <Typography className={classes.smoke} variant="body2">
          {mountAddressInfo(postalAddress.addressLines)}
        </Typography>
      )}
      {(postalAddress.sublocality ||
        postalAddress.locality ||
        postalAddress.administrativeArea) && (
        <Typography className={classes.smoke} variant="body2">
          {mountAddressInfo([
            postalAddress.sublocality,
            postalAddress.locality,
            postalAddress.administrativeArea
          ])}
        </Typography>
      )}
      {postalAddress.postalCode && (
        <Typography className={classes.smoke} variant="body2">
          {formatPostalCode(postalAddress.postalCode)}
        </Typography>
      )}
    </Box>
  );
};

LoggiAddressInfo.propTypes = {
  postalAddress: PropTypes.shape().isRequired
};

const PkgDestinationInfo = ({ packageInfo }) => {
  const classes = useStyles();

  return (
    <Box my={1}>
      <Typography className={classes.smoke} variant="body2">
        {mountDestinationInfo(packageInfo.destination)}
      </Typography>
      <Box>
        <Typography className={classes.smoke} variant="body2">
          {packageInfo.destination.city}
        </Typography>
      </Box>
    </Box>
  );
};

PkgDestinationInfo.propTypes = {
  packageInfo: PropTypes.shape().isRequired
};

export const CurrentAddressInfo = ({ packageInfo }) => {
  const { postalAddress } = packageInfo.loggiAddress || {};
  return postalAddress ? (
    <LoggiAddressInfo postalAddress={postalAddress} />
  ) : (
    <PkgDestinationInfo packageInfo={packageInfo} />
  );
};

CurrentAddressInfo.propTypes = {
  packageInfo: PropTypes.shape().isRequired
};

export const PackageHistoryItem = ({ packageHistory, packageInfo }) => {
  const classes = useStyles();

  return (
    <ListItem
      button
      divider
      onClick={packageHistory}
      classes={{ root: classes.item }}
      disableGutters
    >
      <Grid container alignItems="center" justify="flex-end">
        <Grid className={classes.listItemLabel}>
          <Box ml={3.5} my={2.5}>
            <ListItemText
              primary={
                <Typography variant="body1" data-testid="package-history-item">
                  {TEXT.VIEW_HISTORY_PACKAGE}
                </Typography>
              }
            />
          </Box>
        </Grid>
        {packageInfo.missedPromisedDateEvents && (
          <Grid>
            <MissedEventsBox
              count={packageInfo.missedPromisedDateEvents.length}
            />
          </Grid>
        )}
        <Grid>
          <Box
            ml={2}
            mr={3.5}
            my={2.5}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <ArrowForwardIosIcon
              className={classes.fontSizeIcon}
              color="primary"
              size="small"
            />
          </Box>
        </Grid>
      </Grid>
    </ListItem>
  );
};

PackageHistoryItem.propTypes = {
  packageHistory: PropTypes.func.isRequired,
  packageInfo: PropTypes.shape().isRequired
};

export const ProofOfDeliveryItem = ({ proofOfDelivery }) => {
  const classes = useStyles();

  return (
    <ListItem
      button
      divider
      onClick={proofOfDelivery}
      classes={{ root: classes.item }}
      disableGutters
    >
      <Grid container alignItems="center" justify="flex-end">
        <Grid className={classes.listItemLabel}>
          <Box ml={3.5} my={2.5}>
            <ListItemText
              primary={
                <Typography
                  variant="body1"
                  data-testid="proof-of-delivery-item"
                >
                  {TEXT.VIEW_PROOF_OF_DELIVERY}
                </Typography>
              }
            />
          </Box>
        </Grid>
        <Grid>
          <Box
            ml={2}
            mr={3.5}
            my={2.5}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <ArrowForwardIosIcon
              className={classes.fontSizeIcon}
              color="primary"
              size="small"
            />
          </Box>
        </Grid>
      </Grid>
    </ListItem>
  );
};

ProofOfDeliveryItem.propTypes = {
  proofOfDelivery: PropTypes.func.isRequired
};

export const DisputeProtocolItem = ({ disputeProtocol }) => {
  const classes = useStyles();

  return (
    <ListItem
      button
      divider
      onClick={disputeProtocol}
      classes={{ root: classes.item }}
      disableGutters
    >
      <Grid container alignItems="center" justify="flex-end">
        <Grid className={classes.listItemLabel}>
          <Box ml={3.5} my={2.5}>
            <ListItemText
              primary={
                <Typography
                  variant="body1"
                  data-testid="proof-of-delivery-item"
                >
                  {TEXT.VIEW_DISPUTE_PROTOCOL}
                </Typography>
              }
            />
          </Box>
        </Grid>
        <Grid>
          <Box
            ml={2}
            mr={3.5}
            my={2.5}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <ArrowForwardIosIcon
              className={classes.fontSizeIcon}
              color="primary"
              size="small"
            />
          </Box>
        </Grid>
      </Grid>
    </ListItem>
  );
};

DisputeProtocolItem.propTypes = {
  disputeProtocol: PropTypes.func.isRequired
};

export const HeaderPackageInfo = ({
  packageInfo,
  packageChargeDisplayInfo,
  shouldPreserveStatus,
  isAtFinishedScreen,
  classes,
  showBtnCalculateDeadline,
  setDisableBtnCalculatePromisedDate,
  forceCreatePromisedDate,
  disableBtnCalculatePromisedDate,
  statusPackageCode,
  isLeve,
  showLastMilePromisedDate,
  chooseTextPromisedDate,
  choosePromisedDate,
  isDelivered,
  isFinished,
  isDisputeSolved,
  statusDeliveredFilter,
  statusFinalizedFilter,
  getDisputeSolvedDate,
  isAtRisk,
  showPromisedDateInDrawer
}) => {
  return (
    <Box mx={3.5} mb={2}>
      <Box mt={2} mb={1}>
        <StatusColorDescription
          status={packageInfo.status.code}
          statusDescription={packageInfo.statusDisplay}
          preserveStatusDescription={shouldPreserveStatus}
          typographyVariant="body1"
        />
      </Box>
      {statusCodeDescription[statusPackageCode] && isLeve && (
        <Box pb={1.5} display="flex" justifyContent="space-between">
          <Typography
            variant="subtitle2"
            className={classes.item}
            data-testid="status-message"
          >
            {statusCodeDescription[statusPackageCode]}
          </Typography>
        </Box>
      )}

      <Box>
        {showLastMilePromisedDate && (
          <Typography variant="subtitle2" data-testid="arrived-at">
            {TEXT.ARRIVED}
            {dateFormatter(
              packageInfo.lastMilePromisedDate.promisedDate.triggerDate,
              'DD MMM, [às] HH:mm'
            )}
          </Typography>
        )}
        {(showPromisedDateInDrawer ||
          packageInfo?.lastMilePromisedDate?.originalPromisedDate) && (
          <Box display="flex" flexDirection="row" alignItems="center">
            {showPromisedDateInDrawer && (
              <Typography variant="subtitle2" data-testid="promised-date">
                {`${chooseTextPromisedDate()}${dateFormatter(
                  choosePromisedDate()
                )}`}
              </Typography>
            )}
            {packageInfo?.lastMilePromisedDate?.originalPromisedDate && (
              <Chip
                label="Prazo Extra Aplicado"
                icon={<InsightIcon />}
                classes={{
                  root: classes.extendedDeadlineChip,
                  label: classes.extendedDeadlineChipLabel
                }}
              />
            )}
          </Box>
        )}
        {isDelivered && (
          <Typography variant="subtitle2" className={classes.item}>
            {TEXT.DELIVERED}
            {dateFormatter(statusDeliveredFilter())}
          </Typography>
        )}
        {isFinished && !isDisputeSolved && (
          <Typography variant="subtitle2" className={classes.item}>
            {TEXT.FINISHED}
            {dateFormatter(statusFinalizedFilter())}
          </Typography>
        )}
        {isFinished && isDisputeSolved && (
          <Typography
            variant="subtitle2"
            className={classes.item}
            data-testid="delivery-confirmed-date"
          >
            {TEXT.FINISHED}
            {dateFormatter(statusFinalizedFilter())}
            {TEXT.DELIVERY_CONFIRMED}
            {dateFormatter(getDisputeSolvedDate())}
          </Typography>
        )}
        {packageInfo.regionLabel && (
          <Box pt={2.5}>
            <Typography variant="subtitle2">
              {packageInfo.regionLabel}
            </Typography>
          </Box>
        )}
      </Box>
      {!packageChargeDisplayInfo?.packageCharge?.totalValue && (
        <Box mt={2}>
          <ChunkHeaderPackageComponent
            isAtFinishedScreen={isAtFinishedScreen}
            showBtnCalculateDeadline={showBtnCalculateDeadline}
            setDisableBtnCalculatePromisedDate={
              setDisableBtnCalculatePromisedDate
            }
            forceCreatePromisedDate={forceCreatePromisedDate}
            disableBtnCalculatePromisedDate={disableBtnCalculatePromisedDate}
            packageInfo={packageInfo}
          />
        </Box>
      )}
      {packageChargeDisplayInfo?.packageCharge?.totalValue && (
        <>
          <Box my={2}>
            <Divider />
          </Box>
          <ChunkHeaderPackageValoresComponent
            packageChargeDisplayInfo={packageChargeDisplayInfo}
          />
        </>
      )}
      {isAtRisk && (
        <Box
          borderRadius={8}
          p={3}
          data-testid="alert-package-at-risk"
          bgcolor={colors.blue[50]}
        >
          <Typography>
            <em>{TEXT.ALERT_PACKAGE_AT_RISK}</em>
          </Typography>
          <Typography>{TEXT.ALERT_PACKAGE_AT_RISK_FIND_PACKAGE}</Typography>
        </Box>
      )}
    </Box>
  );
};

HeaderPackageInfo.propTypes = {
  packageInfo: PropTypes.shape().isRequired,
  packageChargeDisplayInfo: PropTypes.shape().isRequired,
  shouldPreserveStatus: PropTypes.func.isRequired,
  isAtFinishedScreen: PropTypes.bool.isRequired,
  classes: PropTypes.shape().isRequired,
  showBtnCalculateDeadline: PropTypes.func.isRequired,
  setDisableBtnCalculatePromisedDate: PropTypes.func.isRequired,
  forceCreatePromisedDate: PropTypes.func.isRequired,
  disableBtnCalculatePromisedDate: PropTypes.bool.isRequired,
  statusPackageCode: PropTypes.number.isRequired,
  isLeve: PropTypes.bool.isRequired,
  showLastMilePromisedDate: PropTypes.bool.isRequired,
  chooseTextPromisedDate: PropTypes.func.isRequired,
  choosePromisedDate: PropTypes.func.isRequired,
  isDelivered: PropTypes.bool.isRequired,
  isFinished: PropTypes.bool.isRequired,
  isDisputeSolved: PropTypes.bool.isRequired,
  statusDeliveredFilter: PropTypes.func.isRequired,
  statusFinalizedFilter: PropTypes.func.isRequired,
  getDisputeSolvedDate: PropTypes.func.isRequired,
  isAtRisk: PropTypes.bool.isRequired,
  showPromisedDateInDrawer: PropTypes.bool.isRequired
};

export const PackageDetail = ({
  packageInfo,
  isReturnDirection,
  changeAddress,
  disableChangeAddress,
  removeQualityFlags,
  areQualityFlagsRemoved
}) => {
  const classes = useStyles();

  const getSuspiciousGeocoding = () => {
    return packageInfo?.loggiAddress?.qualityFlags?.suspiciousGeocoding;
  };

  const getHierarchicalFallbackLevel = () => {
    const excludedHierarchicalFallbackLevels = [
      HIERARCHICAL_FALLBACK_LEVEL_INVALID,
      HIERARCHICAL_FALLBACK_LEVEL_UNUSED
    ];
    const hierarchicalFallbackLevel =
      packageInfo?.loggiAddress?.qualityFlags?.hierarchicalFallbackLevel;

    return (
      hierarchicalFallbackLevel &&
      !excludedHierarchicalFallbackLevels.includes(hierarchicalFallbackLevel)
    );
  };

  const isThereAnyQualityFlagPresent = () => {
    return (
      !areQualityFlagsRemoved &&
      (getSuspiciousGeocoding() || getHierarchicalFallbackLevel())
    );
  };

  const isAgency = getCompanyType() === 'AGENCY';

  return (
    <Box mx={3.5} my={3.5} data-testid="package-detail-info">
      <Box>
        <Typography variant="body2">
          <em>{`${packageInfo.company.name} #${
            packageInfo.integrationInfo.barcode
          }`}</em>
        </Typography>
      </Box>
      <Box my={1}>
        {isReturnDirection ? (
          <Typography className={classes.smoke} variant="body2">
            {TEXT.PACKAGE_DIRECTION_RETURNING}
          </Typography>
        ) : (
          <Typography className={classes.smoke} variant="body2">
            {TEXT.PACKAGE_DIRECTION_DELIVERING}
          </Typography>
        )}
      </Box>
      <Box my={1}>
        <Typography className={classes.smoke} variant="body2">
          {packageInfo?.recipient?.name || 'empty_recipient'}{' '}
          {formatPhone(packageInfo?.recipient?.phone)}
        </Typography>
      </Box>
      <CurrentAddressInfo packageInfo={packageInfo} />
      <>
        {!disableChangeAddress && isThereAnyQualityFlagPresent() && isAgency && (
          <Box
            borderRadius={8}
            p={3}
            data-testid="alert-info-confer-location"
            bgcolor={colors.blue[50]}
          >
            <Typography>
              <em>
                {TEXT.ALERT_TITLE_CONFER_LOCATION_HIERARCHICAL_FALLBACK_LEVEL}
              </em>
            </Typography>
            <Typography>{TEXT.ALERT_DESCRIBE_CONFER_LOCATION}</Typography>
            <Box display="flex" justifyContent="flex-end" mt={1}>
              <Button color="primary" onClick={removeQualityFlags}>
                {TEXT.BTN_LOCATION_IS_RIGHT}
              </Button>
              <Button
                color="primary"
                onClick={() => changeAddress({ withMapOpen: true })}
              >
                {TEXT.BTN_CONFER_LOCATION}
              </Button>
            </Box>
          </Box>
        )}

        {areQualityFlagsRemoved && (
          <Box
            p={3}
            data-testid="alert-info-removed-location"
            display="flex"
            bgcolor={colors.blue[50]}
          >
            <Box mr={2}>
              <CheckCircleIcon color="primary" />
            </Box>
            <Typography>{TEXT.ALERT_FLAG_REMOVE_SUCESS}</Typography>
          </Box>
        )}
      </>
    </Box>
  );
};

PackageDetail.defaultProps = {
  isReturnDirection: false,
  disableChangeAddress: null,
  removeQualityFlags: null,
  areQualityFlagsRemoved: false
};

PackageDetail.propTypes = {
  packageInfo: PropTypes.shape().isRequired,
  changeAddress: PropTypes.func.isRequired,
  disableChangeAddress: PropTypes.func,
  isReturnDirection: PropTypes.bool,
  removeQualityFlags: PropTypes.func,
  areQualityFlagsRemoved: PropTypes.bool
};

const formatName = name => {
  const connectorsRegex = /\b(?:de|da)\b/gi;
  const transformedName = name
    .toLowerCase()
    .replace(/\b\w/g, match => match.toUpperCase());
  return transformedName.replace(connectorsRegex, match => match.toLowerCase());
};

export const DelivererDetail = ({ packageInfo, isAtFinishedScreen }) => {
  const classes = useStyles();
  const showDeliverDetail =
    unitLoadMapper(packageInfo, isAtFinishedScreen).includes(
      WHERE_IS_THE_PACKAGE.DELIVERY
    ) && packageInfo?.custody;
  return showDeliverDetail ? (
    <>
      <Divider />
      <Box className={classes.delivererImage} mx={3.5} my={3.5}>
        <Box mr={2} my={2}>
          <Avatar className={classes.roundedImage} />
        </Box>
        <Box>
          <Box>
            <Typography variant="subtitle2">
              <em>{formatName(packageInfo.custody.full_name)}</em>
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.smoke} variant="body2">
              {formatPhone(packageInfo.custody.mobile_1)}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider />
    </>
  ) : null;
};

DelivererDetail.propTypes = {
  isAtFinishedScreen: PropTypes.bool.isRequired,
  packageInfo: PropTypes.shape().isRequired
};

const DiscountAccordion = ({ packageChargeDisplayInfo, packageInfo }) => {
  const classes = useStyles();

  const disputedDeduction = (packageChargeDisplayInfo?.deductions || []).filter(
    d => d.type === DEDUCTIONS_TYPES.DISPUTED
  )[0];

  const delayedDeduction = (packageChargeDisplayInfo?.deductions || []).filter(
    d => d.type === DEDUCTIONS_TYPES.DELAYED
  )[0];

  return disputedDeduction ||
    (delayedDeduction &&
      (packageInfo.missedPromisedDateEvents ?? []).length > 0) ? (
    <Box px={1.5} className={classes.customAccordion}>
      <Accordion
        className={classes.customAccordion}
        data-testid="discount-details"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
        >
          <Typography className={classes.bolder}>
            {TEXT.ALERT_DISCOUNT}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {TEXT.DETAILS_DISCOUNT}
            <Link
              href="https://ajuda.loggi.com/hc/pt-br/articles/17231455087629-Descontos-Acarea%C3%A7%C3%A3o"
              target="_blank"
              className={classes.bolder}
            >
              {TEXT.CENTRAL_DE_AJUDA}
            </Link>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  ) : null;
};

DiscountAccordion.propTypes = {
  packageChargeDisplayInfo: PropTypes.shape().isRequired,
  packageInfo: PropTypes.shape().isRequired
};

export const StatusItem = ({ packageInfo, changeStatus }) => {
  const classes = useStyles();
  const processDestinationOrContext = getProcessDestinationOrContext(
    packageInfo
  );

  const returning = ['return:loggi', 'receiving:rejected', 'REFUSED'];

  const disable =
    PACKAGE_STATUS_CODE_UNAVAILABLE_TO_CHANGE.indexOf(
      packageInfo.status.code
    ) !== -1 || returning.includes(processDestinationOrContext);

  return (
    <Tooltip
      title={TEXT.UNAVAILABLE_CHANGE_STATUS}
      disableHoverListener={!disable}
    >
      <Box>
        <ListItem
          button
          divider
          disabled={disable}
          data-testid="changed"
          onClick={() => changeStatus(true)}
          classes={{ root: classes.item }}
          disableGutters
        >
          <Grid container alignItems="center" justify="space-between">
            <Grid>
              <Box mx={3.5} my={2.5}>
                <ListItemText
                  primary={
                    <Typography variant="body1">
                      {TEXT.CHANGE_STATUS}
                    </Typography>
                  }
                />
              </Box>
            </Grid>
            <Grid>
              <Box mx={3.5} my={2.5}>
                <ArrowForwardIosIcon
                  className={classes.fontSizeIcon}
                  color="primary"
                  size="small"
                />
              </Box>
            </Grid>
          </Grid>
        </ListItem>
      </Box>
    </Tooltip>
  );
};

StatusItem.defaultProps = {
  changeStatus: null
};

StatusItem.propTypes = {
  changeStatus: PropTypes.func,
  packageInfo: PropTypes.shape().isRequired
};

export const DownloadCTEItem = ({ packageInfo }) => {
  const classes = useStyles();
  const disable = !packageInfo.cteUrl;
  return (
    <Tooltip title={TEXT.PACKAGE_WITHOUT_CTE} disableHoverListener={!disable}>
      <ListItem
        button
        divider
        disabled={disable}
        data-testid="printed"
        onClick={() => (!disable ? window.open(packageInfo.cteUrl) : null)}
        classes={{ root: classes.item }}
        disableGutters
      >
        <Grid container alignItems="center" justify="space-between">
          <Grid>
            <Box ml={3.5} my={2.5}>
              <ListItemText
                primary={
                  <Typography variant="body1">{TEXT.CTE_LABEL}</Typography>
                }
              />
            </Box>
          </Grid>
          <Grid>
            <Box mr={3.5} my={2.5}>
              <ArrowForwardIosIcon
                className={classes.fontSizeIcon}
                color="primary"
                size="small"
              />
            </Box>
          </Grid>
        </Grid>
      </ListItem>
    </Tooltip>
  );
};

DownloadCTEItem.propTypes = {
  packageInfo: PropTypes.shape().isRequired
};

export const RefusedPackageItem = ({ refusePackage }) => {
  const classes = useStyles();
  return (
    <ListItem
      button
      divider
      data-testid="refused"
      onClick={refusePackage}
      classes={{ root: classes.item }}
      disableGutters
    >
      <Grid container alignItems="center" justify="space-between">
        <Grid>
          <Box ml={3.5} my={2.5}>
            <ListItemText
              primary={
                <Typography variant="body1">{TEXT.REFUSED_PACKAGE}</Typography>
              }
            />
          </Box>
        </Grid>
        <Grid>
          <Box mr={3.5} my={2.5}>
            <ArrowForwardIosIcon
              className={classes.fontSizeIcon}
              color="primary"
              size="small"
            />
          </Box>
        </Grid>
      </Grid>
    </ListItem>
  );
};

RefusedPackageItem.propTypes = {
  refusePackage: PropTypes.func
};

RefusedPackageItem.defaultProps = {
  refusePackage: null
};

export const ChangeDelivererItem = ({ changeDeliverer }) => {
  const classes = useStyles();
  return (
    <ListItem
      button
      divider
      data-testid="change-deliverer"
      onClick={changeDeliverer}
      classes={{ root: classes.item }}
      disableGutters
    >
      <Grid container alignItems="center" justify="space-between">
        <Grid>
          <Box ml={3.5} my={2.5}>
            <ListItemText
              primary={
                <Typography variant="body1">{TEXT.CHANGE_DELIVERER}</Typography>
              }
            />
          </Box>
        </Grid>
        <Grid>
          <Box mr={3.5} my={2.5}>
            <ArrowForwardIosIcon
              className={classes.fontSizeIcon}
              color="primary"
              size="small"
            />
          </Box>
        </Grid>
      </Grid>
    </ListItem>
  );
};

ChangeDelivererItem.propTypes = {
  changeDeliverer: PropTypes.func
};

ChangeDelivererItem.defaultProps = {
  changeDeliverer: null
};

const PackageAddressItem = ({
  packageInDevolution,
  packageFinished,
  packageInfo,
  disableChangeAddress,
  changeAddress
}) => {
  const classes = useStyles();

  let title = '';
  if (packageInDevolution)
    title = TEXT.UNAVAILABLE_CHANGE_ADDRESS_FOR_STATUS_DEVOLUTION;
  else if (packageFinished)
    title = TEXT.UNAVAILABLE_CHANGE_ADDRESS_FOR_STATUS_FINISHED(
      packageInfo.status.code
    );

  return (
    <Tooltip
      title={title}
      disableHoverListener={!disableChangeAddress()}
      data-testid="tooltip-change-address"
    >
      <Box>
        <ListItem
          button
          divider
          disabled={disableChangeAddress()}
          data-testid="change-address"
          onClick={changeAddress}
          classes={{ root: classes.item }}
          disableGutters
        >
          <Grid container alignItems="center" justify="flex-end">
            <Grid className={classes.listItemLabel}>
              <Box ml={3.5} my={2.5}>
                <ListItemText
                  primary={
                    <Typography variant="body1">
                      {TEXT.CHANGE_ADDRESS}
                    </Typography>
                  }
                />
              </Box>
            </Grid>
            <Grid>
              <Box
                ml={2}
                mr={3.5}
                my={2.5}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <ArrowForwardIosIcon
                  className={classes.fontSizeIcon}
                  color="primary"
                  size="small"
                />
              </Box>
            </Grid>
          </Grid>
        </ListItem>
      </Box>
    </Tooltip>
  );
};

PackageAddressItem.propTypes = {
  packageInfo: PropTypes.shape().isRequired,
  changeAddress: PropTypes.func.isRequired,
  packageInDevolution: PropTypes.bool.isRequired,
  disableChangeAddress: PropTypes.func.isRequired,
  packageFinished: PropTypes.bool.isRequired
};

const OriginalAddress = ({
  isReturnDirection,
  originalAddress,
  packageInfo
}) => {
  const classes = useStyles();
  const [openHistory, setOpenHistory] = useState(false);

  return (
    <ListItem
      button
      data-testid="address"
      onClick={() => setOpenHistory(current => !current)}
      style={{
        backgroundColor: colors.smoke[50]
      }}
      disableGutters
      dense
    >
      <Grid container alignItems="center">
        <Box ml={3.5} my={1}>
          <ListItemText
            primary={
              <Typography variant="body2" color="secondary">
                {isReturnDirection
                  ? TEXT.RECIPIENT_ADDRESS
                  : TEXT.ORIGINAL_ADDRESS}
              </Typography>
            }
          />
        </Box>
        <Box mr={1.5} pt={0.6}>
          {openHistory ? (
            <ExpandLessIcon color="primary" size="large" />
          ) : (
            <ExpandMoreIcon color="primary" size="large" />
          )}
        </Box>
        <Collapse in={openHistory} timeout="auto" unmountOnExit>
          <Grid data-testid="address_string">
            <Box mx={3.5} mb={2.5} mt={0.5}>
              <Typography className={classes.smoke} variant="body2">
                {mountDestinationInfo(originalAddress)}
              </Typography>
              <Typography className={classes.smoke} variant="body2">
                {packageInfo.destination.city}
              </Typography>
            </Box>
          </Grid>
        </Collapse>
      </Grid>
    </ListItem>
  );
};

OriginalAddress.propTypes = {
  packageInfo: PropTypes.shape().isRequired,
  isReturnDirection: PropTypes.bool.isRequired,
  originalAddress: PropTypes.shape().isRequired
};

export default function DrawerPackageDetail(props) {
  const {
    packageInfo,
    packageChargeDisplayInfo,
    packageHistory,
    changeStatus,
    refusePackage,
    downloadCTE,
    changeDeliverer,
    changeAddress,
    forceCreatePromisedDate,
    removeQualityFlags,
    areQualityFlagsRemoved,
    isAtRisk,
    disputeData,
    driverInfo,
    proofOfDelivery,
    disputeProtocol
  } = props;

  const classes = useStyles();
  const { pathname } = useLocation();
  const isAtFinishedScreen = pathname.includes(KEY_CUSTODY.FINALIZADOS);

  const [
    disableBtnCalculatePromisedDate,
    setDisableBtnCalculatePromisedDate
  ] = useState(false);

  const isDelivered =
    packageInfo.status.code === PACKAGE_STATUS_CODE.DELIVERED &&
    !isAtFinishedScreen;

  const isFinished =
    (packageInfo.status.code === PACKAGE_STATUS_CODE.DELIVERED ||
      packageInfo.status.code === PACKAGE_STATUS_CODE.LOST ||
      packageInfo.status.code === PACKAGE_STATUS_CODE.STOLEN ||
      packageInfo.status.code === PACKAGE_STATUS_CODE.DAMAGED) &&
    isAtFinishedScreen;

  const originalAddress = packageInfo.originalAddress || null;
  const isReturnDirection =
    packageInfo.direction && packageInfo.direction === PACKAGE_DIRECTION_RETURN;

  const showPromisedDate =
    getCompanyType() !== 'LEVE' && packageInfo.promisedDate;

  const showLastMilePromisedDate =
    getCompanyType() === 'LEVE' && packageInfo.lastMilePromisedDate;

  const enableCreateExpectedDateForDrawerAwaitingResolution = IsFSActiveForCurrentBase(
    'enableCreateExpectedDateForDrawerAwaitingResolution'
  );

  const isAwaitingResolution =
    packageInfo.status.code === PACKAGE_STATUS_CODE.AWAITING_RESOLUTION &&
    enableCreateExpectedDateForDrawerAwaitingResolution;

  const showAwaitingResolutionPromisedDate =
    packageInfo.awaitingResolutionPromisedDate && isAwaitingResolution;

  const chooseTextPromisedDate = () => {
    return showAwaitingResolutionPromisedDate
      ? TEXT.AWAITING_BY
      : TEXT.DELIVER_BY;
  };

  const choosePromisedDate = () => {
    if (showAwaitingResolutionPromisedDate) {
      return packageInfo.awaitingResolutionPromisedDate.promisedDate
        ?.promisedDate;
    }
    if (getCompanyType() === 'LEVE') {
      return packageInfo.lastMilePromisedDate.promisedDate?.promisedDate;
    }
    return packageInfo.promisedDate.promised_date;
  };

  const showBtnCalculateDeadline = () => {
    const statusNotValid =
      packageInfo.status.code === PACKAGE_STATUS_CODE.DELIVERED ||
      packageInfo.status.code === PACKAGE_STATUS_CODE.READY_TO_RETURN ||
      packageInfo.status.code === PACKAGE_STATUS_CODE.LOST ||
      packageInfo.status.code === PACKAGE_STATUS_CODE.STOLEN ||
      packageInfo.status.code === PACKAGE_STATUS_CODE.RETURNED ||
      packageInfo.status.code === PACKAGE_STATUS_CODE.SUSPENDED;

    const pkgInBaseOrStreet =
      WHERE_IS_THE_PACKAGE.RECEIVED ===
        unitLoadMapper(packageInfo, isAtFinishedScreen) ||
      WHERE_IS_THE_PACKAGE.DELIVERY ===
        unitLoadMapper(packageInfo, isAtFinishedScreen);

    const pkgWithoutDeadline = !showLastMilePromisedDate;

    return !statusNotValid && pkgInBaseOrStreet && pkgWithoutDeadline;
  };

  const packageFinished =
    packageInfo.status.code === PACKAGE_STATUS_CODE.DELIVERED ||
    packageInfo.status.code === PACKAGE_STATUS_CODE.LOST ||
    packageInfo.status.code === PACKAGE_STATUS_CODE.STOLEN ||
    packageInfo.status.code === PACKAGE_STATUS_CODE.DAMAGED;

  const packageInDevolution = isReturnDirection;

  const disableChangeAddress = () => {
    return packageFinished || packageInDevolution;
  };

  const statusDeliveredFilter = () => {
    const lastStatus = packageInfo.history.find(status => {
      return status?.statusCode === PACKAGE_STATUS_CODE.DELIVERED;
    });

    return lastStatus?.date || null;
  };

  const statusFinalizedFilter = () => {
    const lastStatus = packageInfo.history.find(status => {
      return (
        status?.statusCode === PACKAGE_STATUS_CODE.DELIVERED ||
        status?.statusCode === PACKAGE_STATUS_CODE.LOST ||
        status?.statusCode === PACKAGE_STATUS_CODE.STOLEN ||
        status?.statusCode === PACKAGE_STATUS_CODE.DAMAGED
      );
    });
    return lastStatus?.date || null;
  };

  const isDisputeSolved =
    disputeData?.situation === 'DISPUTE_SITUATION_TYPE_SOLVED';

  const getDisputeSolvedDate = () => {
    return disputeData?.solvedTime;
  };

  const shouldPreserveStatus = () => {
    return getCompanyType() === 'AGENCY';
  };

  const statusPackageCode = packageInfo.status.code;

  const showPromisedDateInDrawer =
    (showLastMilePromisedDate ||
      showPromisedDate ||
      showAwaitingResolutionPromisedDate) &&
    !isDelivered &&
    !isFinished;

  const enableDisputeProtocol = IsFSActiveForCurrentBase(
    SWITCHES.enableDisputeProtocol
  );

  return (
    <Box data-testid="drawer-detail.component">
      <HeaderPackageInfo
        packageInfo={packageInfo}
        packageChargeDisplayInfo={packageChargeDisplayInfo}
        shouldPreserveStatus={shouldPreserveStatus}
        isAtFinishedScreen={isAtFinishedScreen}
        classes={classes}
        showBtnCalculateDeadline={showBtnCalculateDeadline}
        setDisableBtnCalculatePromisedDate={setDisableBtnCalculatePromisedDate}
        forceCreatePromisedDate={forceCreatePromisedDate}
        disableBtnCalculatePromisedDate={disableBtnCalculatePromisedDate}
        statusPackageCode={statusPackageCode}
        isLeve={getCompanyType() === 'LEVE'}
        statusCodeDescription={statusCodeDescription}
        showLastMilePromisedDate={showLastMilePromisedDate}
        TEXT={TEXT}
        dateFormatter={dateFormatter}
        chooseTextPromisedDate={chooseTextPromisedDate}
        choosePromisedDate={choosePromisedDate}
        isDelivered={isDelivered}
        isFinished={isFinished}
        isDisputeSolved={isDisputeSolved}
        statusDeliveredFilter={statusDeliveredFilter}
        statusFinalizedFilter={statusFinalizedFilter}
        getDisputeSolvedDate={getDisputeSolvedDate}
        regionLabel={packageInfo.regionLabel}
        isAtRisk={isAtRisk}
        colors={colors}
        showPromisedDateInDrawer={showPromisedDateInDrawer}
      />
      <DiscountAccordion
        packageChargeDisplayInfo={packageChargeDisplayInfo}
        packageInfo={packageInfo}
        TEXT={TEXT}
        classes={classes}
      />
      <DelivererDetail
        packageInfo={packageInfo}
        isAtFinishedScreen={isAtFinishedScreen}
      />
      {disputeData && (
        <DisputeDetail dispute={disputeData} driver={driverInfo} />
      )}
      <PackageDetail
        packageInfo={packageInfo}
        isReturnDirection={isReturnDirection}
        changeAddress={changeAddress}
        disableChangeAddress={disableChangeAddress()}
        areQualityFlagsRemoved={areQualityFlagsRemoved}
        removeQualityFlags={removeQualityFlags}
      />
      {originalAddress && !packageFinished && (
        <OriginalAddress
          isReturnDirection={isReturnDirection}
          originalAddress={originalAddress}
          packageInfo={packageInfo}
        />
      )}
      <Divider />
      <List disablePadding>
        <Can actions={[ACTIONS.DRAWER_ACTIONS]}>
          {changeStatus && (
            <CanFunction customDisabledTo="loggiPonto">
              <StatusItem
                packageInfo={packageInfo}
                changeStatus={changeStatus}
              />
            </CanFunction>
          )}
          {downloadCTE && <DownloadCTEItem packageInfo={packageInfo} />}
          {changeDeliverer && (
            <ChangeDelivererItem changeDeliverer={changeDeliverer} />
          )}
        </Can>
        <Can actions={[ACTIONS.REFUSE_PACKAGE_WRITE]}>
          {refusePackage && (
            <RefusedPackageItem refusePackage={refusePackage} />
          )}
        </Can>

        <PackageHistoryItem
          packageHistory={packageHistory}
          packageInfo={packageInfo}
        />
        {packageInfo.proofOfDeliveryContent && (
          <ProofOfDeliveryItem proofOfDelivery={proofOfDelivery} />
        )}
        {isDisputeSolved && enableDisputeProtocol && (
          <DisputeProtocolItem disputeProtocol={disputeProtocol} />
        )}
        {changeAddress && (
          <PackageAddressItem
            packageFinished={packageFinished}
            packageInDevolution={packageInDevolution}
            packageInfo={packageInfo}
            disableChangeAddress={disableChangeAddress}
            changeAddress={changeAddress}
          />
        )}
      </List>
    </Box>
  );
}

DrawerPackageDetail.propTypes = {
  packageInfo: PropTypes.shape().isRequired,
  packageChargeDisplayInfo: PropTypes.shape().isRequired,
  packageHistory: PropTypes.func.isRequired,
  changeStatus: PropTypes.func,
  downloadCTE: PropTypes.bool.isRequired,
  refusePackage: PropTypes.func,
  changeDeliverer: PropTypes.func,
  changeAddress: PropTypes.func,
  forceCreatePromisedDate: PropTypes.func.isRequired,
  removeQualityFlags: PropTypes.func,
  areQualityFlagsRemoved: PropTypes.bool,
  isAtRisk: PropTypes.bool,
  disputeData: PropTypes.shape(),
  driverInfo: PropTypes.shape(),
  proofOfDelivery: PropTypes.func,
  disputeProtocol: PropTypes.func
};

DrawerPackageDetail.defaultProps = {
  changeStatus: null,
  refusePackage: null,
  changeDeliverer: null,
  changeAddress: null,
  removeQualityFlags: null,
  areQualityFlagsRemoved: false,
  isAtRisk: false,
  disputeData: null,
  driverInfo: null,
  proofOfDelivery: null,
  disputeProtocol: null
};
