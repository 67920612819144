import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Box, Button, Grid, Popover } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { UPDATE_RESPONSIBLE_TEXTS } from './disputes.constants';

const ButtonUpdateOther = ({ buttonText, driversList, handleSaveOther }) => {
  const [anchor, setAnchor] = useState(null);
  const open = Boolean(anchor);

  const handleClick = event => {
    event.preventDefault();
    setAnchor(event.currentTarget);
  };

  return (
    <Box mr={2} component="span">
      <Button
        data-testid="button-update-dispute-responsible"
        size="small"
        color="primary"
        variant="outlined"
        onClick={handleClick}
      >
        {buttonText}
        {!open && <ArrowDropDownIcon />}
        {open && <ArrowDropUpIcon />}
      </Button>
      <Popover
        anchorEl={anchor}
        open={open}
        onClose={() => setAnchor(null)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        PaperProps={{
          style: {
            width: 400
          }
        }}
      >
        <Box maxWidth="400px" maxHeight="300px" style={{ overflowX: 'hidden' }}>
          {driversList}
        </Box>
        <footer>
          <Box m={3}>
            <Grid container>
              <Grid item xs={4}>
                <Button
                  data-testid="cancel-button"
                  color="primary"
                  fullWidth
                  onClick={() => setAnchor(null)}
                >
                  {UPDATE_RESPONSIBLE_TEXTS.cancelButton}
                </Button>
              </Grid>
              <Grid item xs={8}>
                <Button
                  fullWidth
                  data-testid="confirm-button"
                  variant="outlined"
                  color="primary"
                  type="submit"
                  onClick={() => {
                    handleSaveOther();
                    setAnchor(null);
                  }}
                >
                  {UPDATE_RESPONSIBLE_TEXTS.confirmButton}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </footer>
      </Popover>
    </Box>
  );
};

ButtonUpdateOther.propTypes = {
  buttonText: PropTypes.string.isRequired,
  driversList: PropTypes.node.isRequired,
  handleSaveOther: PropTypes.func.isRequired
};

export default ButtonUpdateOther;
