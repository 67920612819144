import React from 'react';
import { Box, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import { IsFSActiveForCurrentBase } from 'auth/access-control';
import { useFsRealtimeGeneral } from 'infra/firebase/realtime/firebase-realtime-database';
import { UpdateDisputeResponsible } from './disputes-update-responsible.container';
import { DisputeHighlight } from './disputes-highlight.component';
import { DisputesDriverComponent } from './disputes-driver.component';
import { SWITCHES_REALTIME } from '../../view/constants';

export const DisputeDetail = ({ dispute, driver }) => {
  const enableNewDrawerDisputes = useFsRealtimeGeneral(
    SWITCHES_REALTIME.fsEnableNewDrawerDisputes
  );
  const updateResponsiblebuttonFS = IsFSActiveForCurrentBase(
    'enableUpdateDisputeResponsibleButton'
  );
  const hasUpateResponsibleSituation =
    dispute?.situation === 'DISPUTE_SITUATION_TYPE_UNASSIGNED';
  const hasPendingSituation =
    dispute?.situation === 'DISPUTE_SITUATION_TYPE_PENDING';

  const { backGroundColor, content } = DisputeHighlight({
    disputeData: dispute
  });

  return enableNewDrawerDisputes ? (
    <>
      <Box bgcolor={backGroundColor} my={1} mt={2} p={2} ml={2} mx={0}>
        {content}
        {driver &&
          updateResponsiblebuttonFS &&
          hasUpateResponsibleSituation && (
            <DisputesDriverComponent driver={driver} dispute={dispute} />
          )}
      </Box>
      {driver && hasPendingSituation && (
        <>
          <DisputesDriverComponent driver={driver} dispute={dispute} />
          <Divider />
        </>
      )}
    </>
  ) : (
    <>
      <Box bgcolor={backGroundColor} my={1} mt={2} p={2} ml={2} margin={2}>
        {content}
        {updateResponsiblebuttonFS && hasUpateResponsibleSituation && (
          <UpdateDisputeResponsible
            dispute={dispute}
            driverSituation={driver?.isActive}
          />
        )}
      </Box>
      <Divider />
      {driver && hasPendingSituation && (
        <DisputesDriverComponent driver={driver} dispute={dispute} />
      )}
    </>
  );
};

DisputeDetail.propTypes = {
  dispute: PropTypes.shape().isRequired,
  driver: PropTypes.shape().isRequired
};

export default DisputeDetail;
