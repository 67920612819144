import { Box, Divider, Typography } from '@material-ui/core';
import React from 'react';
import formatMoneyProto from 'shared/formatters/money';
import { DEDUCTIONS_TYPES } from 'finance/finance.constants';
import PropTypes from 'prop-types';
import useStyles from '../../drawer.style';

export default function ChunkHeaderPackageValoresComponent({
  packageChargeDisplayInfo
}) {
  const classes = useStyles();

  const disputeDeduction = (packageChargeDisplayInfo?.deductions || []).filter(
    d => d.type === DEDUCTIONS_TYPES.DISPUTED
  )[0];

  const calculateDiscount = (totalValue, discount) => {
    const totalValueDecimal = parseFloat(
      `${totalValue.units}.${totalValue.nanos}`
    );
    const discountDecimal = parseFloat(`${discount.units}.${discount.nanos}`);

    const totalValueWithDiscountDecimal = totalValueDecimal - discountDecimal;

    const totalValueWithDiscountUnits = Math.floor(
      totalValueWithDiscountDecimal
    );
    const totalValueWithDiscountNanos = Math.round(
      (totalValueWithDiscountDecimal - totalValueWithDiscountUnits) * 1e9
    );
    const totalValueWithDiscount = {
      units: totalValueWithDiscountUnits,
      nanos: totalValueWithDiscountNanos
    };

    return totalValueWithDiscount;
  };

  return (
    <>
      {disputeDeduction?.amount ? (
        <Box data-testid="deductions">
          <Box display="flex" flexDirection="column">
            <DiscountMessage
              packageChargeDisplayInfo={packageChargeDisplayInfo}
            />
            <Box display="flex" alignItems="center" mt={1}>
              <Typography className={classes.valueWithDiscount}>
                {`${formatMoneyProto(
                  packageChargeDisplayInfo.packageCharge.totalValue
                )}`}
              </Typography>
              <Typography className={classes.discount} variant="button">
                <em>{`-${formatMoneyProto(disputeDeduction.amount)}`}</em>
              </Typography>
            </Box>
          </Box>
          <Box mt={2} pb={2.5} display="flex" flexDirection="column">
            <Typography variant="body2" className={classes.smoke}>
              Você recebe:
            </Typography>
            <Typography
              className={classes.bolder}
              variant="h4"
              data-testid="value-with-discount"
            >
              {`${formatMoneyProto(
                calculateDiscount(
                  packageChargeDisplayInfo.packageCharge.totalValue,
                  disputeDeduction.amount
                )
              )}`}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box
          pb={2.5}
          display="flex"
          data-testid="no-deductions"
          flexDirection="column"
        >
          <Typography variant="body2" className={classes.smoke}>
            Você recebe:
          </Typography>
          <Typography className={classes.bolder} variant="h4">
            {`${formatMoneyProto(
              packageChargeDisplayInfo.packageCharge.totalValue
            )}`}
          </Typography>
          <Box mt={2}>
            <Divider />
          </Box>
        </Box>
      )}
    </>
  );
}

const typePackageChargeDisplayInfo = PropTypes.shape({
  deductions: PropTypes.arrayOf(PropTypes.shape({})),
  packageCharge: PropTypes.shape({
    totalValue: PropTypes.shape({
      units: PropTypes.number,
      nanos: PropTypes.number
    })
  })
}).isRequired;
ChunkHeaderPackageValoresComponent.propTypes = {
  packageChargeDisplayInfo: typePackageChargeDisplayInfo.isRequired
};

const DiscountMessage = ({ packageChargeDisplayInfo }) => {
  const deductionType = packageChargeDisplayInfo?.deductions?.[0]?.type;

  let discountMessage = 'não mapeado';
  if (deductionType === DEDUCTIONS_TYPES.DELAYED) {
    discountMessage = 'por falta';
  } else if (deductionType === DEDUCTIONS_TYPES.DISPUTED) {
    discountMessage = 'por acareação';
  }

  return (
    <Box>
      Desconto <em>{discountMessage}</em>:
    </Box>
  );
};

DiscountMessage.propTypes = {
  packageChargeDisplayInfo: typePackageChargeDisplayInfo.isRequired
};
