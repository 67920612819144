import React from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import PropTypes from 'prop-types';
import { Box, Chip, Typography } from '@material-ui/core';
import MissedEventsBox from 'shared/missed-events-box';
import {
  KEY_PERIOD,
  SIZE_TYPE,
  CHARGE_TYPE,
  FORMAT,
  CHARGE_TYPE_LABEL,
  CHARGE_TYPE_LABEL_PAST,
  COLUMN_LABEL,
  DEDUCTIONS_TYPES,
  TEXT_DELAYED,
  TEXT_DISCOUNT
} from './finance.constants';
import { useStyles } from './finance.styles';
import { formatMoneyProto } from '../shared/formatters/money';

/**
 * This is a native implementation of the function `get` from `lodash` lib
 * and this code is from https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_get
 */
export const get = (obj, path, defaultValue) => {
  const travel = regexp =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce(
        (res, key) => (res !== null && res !== undefined ? res[key] : res),
        obj
      );
  const result = travel(/[,[\]]+/) || travel(/[,[\].]+/);
  return result === undefined || result === obj ? defaultValue : result;
};

const missedEventsComponent = item => {
  const missedCount = get(item, 'missedEvent', null);
  return missedCount !== 0 ? <MissedEventsBox count={missedCount} /> : null;
};

const missedEventsBox = {
  propsStyleBody: { align: 'left' },
  component: item => missedEventsComponent(item)
};

export const formatDate = (date, _format = FORMAT.DD_MMM_HOURS) => {
  return date
    ? moment(date)
        .locale('pt-BR')
        .format(_format)
    : '-';
};

export const dateFormat = (date, subtract = false, format = FORMAT.DD_MMM) => {
  return formatDate(
    subtract ? moment(date).subtract('1', 'day') : date,
    format
  ).toLowerCase();
};

const ValueWithDiscountChip = ({ value }) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Typography noWrap variant="body2">
        {formatMoneyProto(value)}
      </Typography>
      <Chip
        key={value}
        label={TEXT_DISCOUNT}
        classes={{
          root: classes.discountChip,
          label: classes.discountChipLabel
        }}
      />
    </Box>
  );
};

ValueWithDiscountChip.propTypes = {
  value: PropTypes.shape({}).isRequired
};

const effectiveDateTime = {
  header: COLUMN_LABEL.EFFECTIVE_DATE_TIME,
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  sortBy: 'package_charge.effective_date_time',
  orderDirection: 'desc',
  component: item => {
    const _effectiveDatetime = get(item, 'packageCharge.effectiveDateTime');
    return formatDate(_effectiveDatetime);
  }
};

export const delivererName = {
  header: COLUMN_LABEL.PEOPLE,
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  sortBy: 'package_charge.deliverer_user_name.keyword',
  component: item => get(item, 'packageCharge.delivererUserName')
};

const regionName = {
  header: COLUMN_LABEL.REGION_NAME,
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  sortBy: 'package_charge.region_name.keyword',
  component: item => {
    const _regionName = get(item, 'packageCharge.regionName');
    return _regionName || '-';
  }
};

const sizeType = {
  header: COLUMN_LABEL.SIZE_TYPE,
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  sortBy: 'package_charge.size_type.keyword',
  component: item => {
    const _sizeType = get(item, 'packageCharge.sizeType');
    return _sizeType === SIZE_TYPE.LARGE ? 'Sim' : '-';
  }
};

export const getChargeTypeLabel = (type, value) => {
  switch (type) {
    case CHARGE_TYPE.DELIVERY_PAYMENT:
      return CHARGE_TYPE_LABEL.DELIVERY_PAYMENT;
    case CHARGE_TYPE.TRANSHIPMENT_PAYMENT:
      return CHARGE_TYPE_LABEL.TRANSHIPMENT_PAYMENT;
    case CHARGE_TYPE.COUNTER_PICKUP_PAYMENT:
      return CHARGE_TYPE_LABEL.COUNTER_PICKUP_PAYMENT;
    case CHARGE_TYPE.CUSTOMER_PICKUP_PAYMENT:
      return value === 'R$ 0,50'
        ? CHARGE_TYPE_LABEL.SERVICE_KIND_DROPOFF
        : CHARGE_TYPE_LABEL.CUSTOMER_PICKUP_PAYMENT;
    default:
      return '-';
  }
};

export const getChargeTypeLabelPast = type => {
  switch (type) {
    case CHARGE_TYPE.DELIVERY_PAYMENT:
      return CHARGE_TYPE_LABEL_PAST.DELIVERED;
    case CHARGE_TYPE.TRANSHIPMENT_PAYMENT:
      return CHARGE_TYPE_LABEL_PAST.TRANSHIPPED;
    case CHARGE_TYPE.COUNTER_PICKUP_PAYMENT:
      return CHARGE_TYPE_LABEL_PAST.PICKED_UP;
    case CHARGE_TYPE.CUSTOMER_PICKUP_PAYMENT:
      return CHARGE_TYPE_LABEL_PAST.PICKED_UP;
    default:
      return '-';
  }
};

const chargeType = {
  header: COLUMN_LABEL.CHARGE_TYPE,
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  sortBy: 'package_charge.charge_type.keyword',
  component: item => {
    const _chargeType = get(item, 'packageCharge.chargeType');
    const totalValue = get(item, 'packageCharge.totalValue');
    return getChargeTypeLabel(_chargeType, formatMoneyProto(totalValue));
  }
};

const value = {
  header: COLUMN_LABEL.VALUE,
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  sortBy: 'package_charge.total_value',
  component: item => {
    const deductionsList = get(item, 'deductions', []);
    const missedEvents = get(item, 'missedEvent');
    const deductionElement = deductionsList[0];
    const totalValue = get(item, 'packageCharge.totalValue');
    if (deductionElement || missedEvents > 0)
      return <ValueWithDiscountChip value={totalValue} />;
    return formatMoneyProto(totalValue);
  }
};

export const deduction = {
  header: COLUMN_LABEL.DEDUCTION,
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  sortBy: 'package_charge.deductions',
  component: item => {
    const deductionsList = get(item, 'deductions', []);
    const missedEvents = get(item, 'missedEvent');
    const deductionElement = deductionsList[0];
    if (!deductionElement) return '-';
    if (deductionElement.type === DEDUCTIONS_TYPES.DISPUTED) {
      return `-${formatMoneyProto(deductionElement.amount)}`;
    }
    if (missedEvents === 0) return '-';
    return TEXT_DELAYED;
  }
};

export const DISPLAY_TABLE_FINANCE_V1 = {
  missedEventsBox,
  effectiveDateTime,
  delivererName,
  regionName,
  sizeType,
  chargeType,
  value
};

const TABLE_CONFIGURATION_V1 = {
  orderBy: [
    effectiveDateTime,
    delivererName,
    regionName,
    sizeType,
    chargeType,
    value
  ],
  tableDisplay: DISPLAY_TABLE_FINANCE_V1
};

export const DISPLAY_TABLE_FINANCE_V2 = {
  missedEventsBox,
  effectiveDateTime,
  delivererName,
  regionName,
  sizeType,
  chargeType,
  value,
  deduction
};

export const TABLE_CONFIGURATION_V2 = {
  orderBy: [
    effectiveDateTime,
    delivererName,
    regionName,
    sizeType,
    chargeType,
    value,
    deduction
  ],
  tableDisplay: DISPLAY_TABLE_FINANCE_V2
};

export const DISPLAY_TABLE_FINANCE_V3 = {
  missedEventsBox,
  effectiveDateTime,
  delivererName,
  regionName,
  chargeType,
  value
};

export const TABLE_CONFIGURATION_V3 = {
  orderBy: [effectiveDateTime, delivererName, regionName, chargeType, value],
  tableDisplay: DISPLAY_TABLE_FINANCE_V3
};

export const PERIOD_MAP = {
  [KEY_PERIOD.CLOSE]: {
    title: 'Fechado'
  },
  [KEY_PERIOD.OPEN]: {
    title: 'Aberto'
  },
  [KEY_PERIOD.PREVIOUS]: {
    title: 'Anteriores'
  }
};

export const formatValue = (totalValue = 0.0) => {
  return Intl.NumberFormat('pt', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
  }).format(totalValue);
};

export const mountSubTitle = (startDate, endDate, period, totalValue) => {
  // open -> R$ 10,00 de 1 jan ate ontem
  // closed -> R$ 10,00 de 1 jan ate 15 de jan
  // previous -> R$ 10,00 entre os dias de 1 jan ate 15 de jan

  return {
    [KEY_PERIOD.OPEN]: `${formatValue(totalValue)} de ${dateFormat(
      startDate
    )} até ontem`,
    [KEY_PERIOD.CLOSE]: `${formatValue(totalValue)} de ${dateFormat(
      startDate
    )} à ${dateFormat(endDate, true)}`,
    [KEY_PERIOD.PREVIOUS]: `${formatValue(totalValue)} valores entre os dias `
  }[period];
};

export const getExpectDate = (allPeriods, period) => {
  if (period === KEY_PERIOD.OPEN && allPeriods.openInvoice) {
    return allPeriods.openInvoice;
  }
  if (period === KEY_PERIOD.CLOSE && allPeriods.lastClosedInvoice) {
    return allPeriods.lastClosedInvoice;
  }
  if (period === KEY_PERIOD.PREVIOUS && allPeriods.allIntervals.length > 0) {
    return allPeriods.allIntervals.slice(-1)[0];
  }
  return { start: '', end: '' };
};

export default TABLE_CONFIGURATION_V1;
