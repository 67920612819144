import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Avatar } from '@material-ui/core';
import { TRANSPORT_TYPE } from 'users-management/drivers/drivers.constants';
import formatPhone from 'shared/formatters/phone/phone';
import { UpdateDisputeResponsible } from './disputes-update-responsible.container';
import {
  UPDATE_RESPONSIBLE_TEXTS,
  INACTIVE_DRIVER
} from './disputes.constants';

export const DisputesDriverComponent = ({
  driver: {
    fullName,
    mobileNumber,
    deliverer: { profilePictureThumbnailUrl, transportType, isActive = true }
  },
  dispute
}) => {
  const hasSetResponsibleSituation =
    dispute?.situation === 'DISPUTE_SITUATION_TYPE_UNASSIGNED';

  const transportTypeFormatted =
    TRANSPORT_TYPE[transportType].charAt(0).toUpperCase() +
    TRANSPORT_TYPE[transportType].slice(1).toLowerCase();

  return (
    <Box p={3} data-testid="driver-info">
      <Typography>
        {hasSetResponsibleSituation
          ? UPDATE_RESPONSIBLE_TEXTS.textDriverResponsible
          : UPDATE_RESPONSIBLE_TEXTS.textDisputeResponsible}
      </Typography>
      <Box pt={1.5} component="span" display="flex" alignItems="center">
        <Box mr={3}>
          <Avatar
            style={{ height: '3em', width: '3em' }}
            src={profilePictureThumbnailUrl}
          />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Typography variant="subtitle2">
            <em>{fullName}</em>
          </Typography>
          <Typography variant="inherit" color="textSecondary">
            {isActive ? (
              <>
                {transportTypeFormatted} • {formatPhone(mobileNumber)}
              </>
            ) : (
              <>{INACTIVE_DRIVER}</>
            )}
          </Typography>
        </Box>
      </Box>
      <UpdateDisputeResponsible dispute={dispute} driverSituation={isActive} />
    </Box>
  );
};

DisputesDriverComponent.propTypes = {
  driver: PropTypes.shape({
    fullName: PropTypes.string,
    mobileNumber: PropTypes.string,
    deliverer: PropTypes.shape({
      transportType: PropTypes.string,
      profilePictureThumbnailUrl: PropTypes.string,
      isActive: PropTypes.bool
    })
  }),
  dispute: PropTypes.shape().isRequired
};

DisputesDriverComponent.defaultProps = {
  driver: {
    fullName: null,
    mobileNumber: null,
    deliverer: {
      transportType: null,
      profilePictureThumbnailUrl: null,
      isActive: null
    }
  }
};

export default DisputesDriverComponent;
