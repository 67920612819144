import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { CanFunction } from 'auth/access-control';
import { Box, Paper, Tabs, Tab } from '@material-ui/core';
import LoadingCard from 'shared/loading/loading-card.component';

import { useHistory, useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

import { AUTHORIZED_ROUTES } from 'view/constants';
import { UsersManagementContext } from './users-management.context';

const TAB = {
  OPERATORS: 0,
  DRIVERS: 1
};

const useStyles = makeStyles({
  root: {
    borderBottom: `1px solid ${colors.smoke[200]}`
  }
});

export default function UsersManagementProvider({ children }) {
  const { url } = useRouteMatch();
  const history = useHistory();
  const style = useStyles();
  const [value, setValue] = useState(
    history.location.pathname.indexOf(
      AUTHORIZED_ROUTES.USERS_MANAGEMENT.DRIVERS
    ) >= 0
      ? TAB.DRIVERS
      : TAB.OPERATORS
  );
  const [cardContent, setCardContent] = useState(<LoadingCard />);

  const tabs = CanFunction({ customDisabledTo: 'loggiPonto' })
    ? ['Para base', 'Para rua']
    : ['Para base'];

  const handleChange = (event, newValue) => {
    setValue(newValue);

    if (newValue === TAB.OPERATORS) {
      history.push(`${url}${AUTHORIZED_ROUTES.USERS_MANAGEMENT.OPERATORS}`);
    } else {
      history.push(`${url}${AUTHORIZED_ROUTES.USERS_MANAGEMENT.DRIVERS}`);
    }
  };

  return (
    <UsersManagementContext.Provider value={{ setCardContent }}>
      <Paper component={Box} elevation={10} flexGrow={1} mb={6} minHeight={44}>
        <Box pt={1} px={5.5}>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            value={value}
            onChange={handleChange}
            className={style.root}
          >
            {tabs.map(tab => (
              <Tab label={tab} key={tab} />
            ))}
          </Tabs>
        </Box>
        {cardContent && (
          <Box pr={5.5} pl={5.5} pt={4.5} pb={4.5}>
            {cardContent}
          </Box>
        )}
      </Paper>
      <>{children}</>
    </UsersManagementContext.Provider>
  );
}

UsersManagementProvider.propTypes = {
  children: PropTypes.node.isRequired
};
