import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { getDriversService } from 'users-management/drivers/drivers.service';
import showSnackbar from 'shared/snackbar';
import { useSnackbar } from 'notistack';
import { IsFSActiveForCurrentBase } from 'auth/access-control';
import { useFsRealtimeGeneral } from 'infra/firebase/realtime/firebase-realtime-database';
import ButtonUpdateAssignSelf from './disputes-button-update-assignself-responsible.component';
import ButtonUpdateOther from './disputes-button-update-other-responsible.component';
import { DisputeDriversList } from './disputes-drivers-list.component';
import { updateDisputeResponsible } from './disputes.service';
import { UPDATE_RESPONSIBLE_TEXTS } from './disputes.constants';
import { SWITCHES_REALTIME } from '../../view/constants';

export const UpdateDisputeResponsible = ({ dispute, driverSituation }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [{ users = [] }, setData] = useState({});
  const [isGetDriversCalled, setIsGetDriversCalled] = useState(true);
  const [selectedDriver, setSelectedDriver] = useState();

  const updateResponsiblebuttonFS = IsFSActiveForCurrentBase(
    'enableUpdateDisputeResponsibleButton'
  );

  const enableNewDrawerDisputes = useFsRealtimeGeneral(
    SWITCHES_REALTIME.fsEnableNewDrawerDisputes
  );

  const hasUpateResponsibleSituation =
    dispute?.situation === 'DISPUTE_SITUATION_TYPE_PENDING';

  const hasSetResponsibleSituation =
    dispute?.situation === 'DISPUTE_SITUATION_TYPE_UNASSIGNED';

  const getButtonText = () => {
    if (hasUpateResponsibleSituation) {
      return UPDATE_RESPONSIBLE_TEXTS.buttonWithResponsible;
    }
    if (hasSetResponsibleSituation) {
      return enableNewDrawerDisputes
        ? UPDATE_RESPONSIBLE_TEXTS.buttonWithoutResponsibleAssignOther
        : UPDATE_RESPONSIBLE_TEXTS.buttonWithoutResponsible;
    }
    return '';
  };

  const updateDriver = driverId => {
    updateDisputeResponsible(dispute.disputeId, driverId)
      .then(() => {
        showSnackbar({
          message: UPDATE_RESPONSIBLE_TEXTS.labelSuccessDriverAllocation,
          variant: 'success',
          enqueueSnackbar
        });
      })
      .catch(error => {
        if (error?.response?.status) {
          showSnackbar({
            message: `${UPDATE_RESPONSIBLE_TEXTS.labelErrorDriverAllocation} (${
              error.message
            })`,
            variant: 'error',
            enqueueSnackbar
          });
        }
      });
  };

  const handleSaveOther = () => {
    updateDriver(selectedDriver);
  };

  const handleSaveAssignSelf = () => {
    updateDriver(dispute.deliveryResponsibleId);
  };

  const handleSelectDriver = driverId => {
    setSelectedDriver(driverId);
  };

  useEffect(() => {
    if (isGetDriversCalled) {
      getDriversService(0)
        .then(response => {
          setData(response);
          setIsGetDriversCalled(false);
        })
        .catch(error => {
          showSnackbar({
            message: `${UPDATE_RESPONSIBLE_TEXTS.labelErrorGetDrivers} (${
              error.message
            })`,
            variant: 'error',
            enqueueSnackbar
          });
        });
    }
  }, [isGetDriversCalled, enqueueSnackbar]);

  const driversList = (
    <DisputeDriversList users={users} handleSelectDriver={handleSelectDriver} />
  );

  const buttonText = getButtonText();

  return enableNewDrawerDisputes ? (
    <>
      {updateResponsiblebuttonFS && hasSetResponsibleSituation && (
        <div style={{ display: 'flex', gap: '5px', marginTop: '15px' }}>
          <ButtonUpdateAssignSelf
            handleSaveAssignSelf={handleSaveAssignSelf}
            driverSituation={driverSituation}
          />
          <ButtonUpdateOther
            buttonText={buttonText}
            driversList={driversList}
            handleSaveOther={handleSaveOther}
          />
        </div>
      )}
      {updateResponsiblebuttonFS && hasUpateResponsibleSituation && (
        <Box my={3}>
          <ButtonUpdateOther
            buttonText={buttonText}
            driversList={driversList}
            handleSaveOther={handleSaveOther}
          />
        </Box>
      )}
    </>
  ) : (
    <>
      {updateResponsiblebuttonFS && buttonText && (
        <Box my={3}>
          <ButtonUpdateOther
            buttonText={buttonText}
            driversList={driversList}
            handleSaveOther={handleSaveOther}
          />
        </Box>
      )}
    </>
  );
};

UpdateDisputeResponsible.propTypes = {
  dispute: PropTypes.shape().isRequired,
  driverSituation: PropTypes.bool.isRequired
};

export default UpdateDisputeResponsible;
