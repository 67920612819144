import api from 'infra/api/leve';
import apiLW from 'infra/api/loggi-web';
import { URL_PROXY_V3_PREFIX, GRAPHQL_URL } from 'infra/service/constants';
import { parseRequestError } from 'app/httpMessage';
import { getLoggedDCId, getLoggedLMCId } from 'auth/login/login.service';
import { TEXTS } from './drivers.constants';

export const LEVE_LINK = '/arco/api/v1/last_mile/link';
export const DRIVER_LINK = '/arco/api/v1/last_mile/link_driver';
export const CREATE_DRIVER_URL =
  '/proxy/api/v2/drivers/create-driver-from-user';
export const GET_DRIVERS_URL = page =>
  `${URL_PROXY_V3_PREFIX}/drivers?page=${page}`;
export const DISABLE_DRIVER_URL = driverId =>
  `${URL_PROXY_V3_PREFIX}/drivers/${driverId}/disable-driver`;
export const UPDATE_DRIVER_URL = driverId =>
  `/proxy/api/v2/drivers/${driverId}`;
export const GET_LOGGERS_URL = '/proxy/last-mile/v3/loggers';
export const GET_DRIVER_URL = (driverId, page) =>
  `${URL_PROXY_V3_PREFIX}/drivers?page=${page}&driver_id=${driverId}`;
export const GET_DRIVER_URL_INCLUDE_INACTIVE = (driverId, page) =>
  `${URL_PROXY_V3_PREFIX}/drivers?page=${page}&driver_id=${driverId}&include_inactive=true`;

export const getDriversService = (page = 1) =>
  api
    .get(GET_DRIVERS_URL(page))
    .then(({ data }) => data)
    .catch(parseRequestError());

export const getDriverService = (driverId, page = 1) =>
  api
    .get(GET_DRIVER_URL(driverId, page))
    .then(({ data }) => data)
    .catch(parseRequestError());

export const getDriverServiceIncludeInactive = (driverId, page = 1) =>
  api
    .get(GET_DRIVER_URL_INCLUDE_INACTIVE(driverId, page))
    .then(({ data }) => data)
    .catch(parseRequestError());

export const availableForOfferService = (driverId, availableForOffer) => {
  const availability = availableForOffer ? 'enable' : 'disable';
  const url = `drivers/${driverId}/${availability}-for-offer`;
  return api
    .put(`${URL_PROXY_V3_PREFIX}/${url}`)
    .then(response => response.data)
    .catch(parseRequestError());
};

export const getLinkService = driver => {
  const { fullName, mobileNumber, email, deliverer } = driver;
  const { isDriver, apiKey } = deliverer;
  const url = isDriver ? DRIVER_LINK : LEVE_LINK;
  const body = isDriver
    ? { delivererEmail: email, delivererApiKey: apiKey }
    : { delivererName: fullName, delivererPhone: mobileNumber };

  return api
    .post(url, body)
    .then(response => response.data.url)
    .catch(parseRequestError());
};

const handleError = error => {
  const dataResponse = error?.response?.data;
  const errorMessage =
    dataResponse?.message === undefined && dataResponse?.driverId === undefined
      ? dataResponse
      : dataResponse.message;
  const translatedMessage = TEXTS.DRIVER_ERROR_TRANSLATE_MAP[errorMessage];
  const customErrors = {
    412: TEXTS.DRIVER_ERROR(translatedMessage || errorMessage)
  };
  return parseRequestError(customErrors)(error);
};

export const createDriverService = (driver, userToBeDeactivated = null) => {
  const dcId = getLoggedDCId();
  const lmcId = getLoggedLMCId();
  const defaultCitySlug = 'sp_brasil';
  const body = {
    full_name: driver.fullName.trim(),
    mobile: driver.mobileNumber,
    email: driver.email,
    cpf: driver.cpf,
    transport_type: driver.transportType,
    lmc_identification: lmcId,
    user_to_be_deactivated_id: userToBeDeactivated
  };

  // remove this when we fix the driver without city bug
  if (dcId) {
    body.distribution_center_id = dcId;
  } else {
    body.city_slug = defaultCitySlug;
  }

  return api
    .post(CREATE_DRIVER_URL, body)
    .then(({ data, status }) => ({ data, status }))
    .catch(handleError);
};

export const disableDriverService = driverId => {
  return api.post(DISABLE_DRIVER_URL(driverId), {}).catch(parseRequestError());
};

export const updateDriverService = driver => {
  const body = {
    full_name: driver.full_name,
    email: driver.email,
    mobile: driver.mobile,
    transport_type: driver.transport_type.toString()
  };
  return api.put(UPDATE_DRIVER_URL(driver.id), body).catch(handleError);
};

export const hasDistributionCenter = () => {
  try {
    return Boolean(getLoggedDCId());
  } catch {
    return false;
  }
};

export const getTransportTypesService = () => {
  const body = {
    variables: {},
    query: `{
      availableTransportTypes {
        id: typeId
        name: typeName
      }
    }`
  };
  return apiLW
    .post(GRAPHQL_URL, body)
    .then(({ data: { data } }) => data?.availableTransportTypes);
};

export const getLoggersService = () => {
  return api
    .get(GET_LOGGERS_URL)
    .then(({ data }) => data)
    .catch(handleError);
};
