import { CHARGE_TYPE_LABEL } from '../finance.constants';

export const TEXT_BTN = 'Preparar notas';

export const LABEL_LOCAL = 'Por entrega local';
export const LABEL_ATENDIDA = 'Por entrega atendida';
export const LABEL_PKG = 'Pacotes';
export const LABEL_DELIVERY = `Por ${CHARGE_TYPE_LABEL.DELIVERY_PAYMENT.toLowerCase()}`;
export const LABEL_TRANSHIPMENT = `Por ${CHARGE_TYPE_LABEL.TRANSHIPMENT_PAYMENT.toLowerCase()}`;
export const LABEL_PICKUP = `Em ${CHARGE_TYPE_LABEL.CUSTOMER_PICKUP_PAYMENT.toLowerCase()}`;
export const LABEL_COUNTER = `Via ${CHARGE_TYPE_LABEL.COUNTER_PICKUP_PAYMENT.toLowerCase()}`;

export default TEXT_BTN;
