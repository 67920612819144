import React from 'react';
import PropTypes from 'prop-types';
import { Interpreter } from 'xstate';
import { Box, Typography } from '@material-ui/core';
import TEXT from './drawer-change-deliverer.constants';
import { ChangeDelivererSelectingTemplate } from '../drawer-select-deliverer-template/drawer-select-deliverer-template.component';

const ChangeDelivererSelecting = ({ changeDelivererMachineRef, barcode }) => {
  const header = (
    <Box px={3.5} py={5}>
      <Typography variant="h4">
        <Box fontWeight={700}>{TEXT.TITLE}</Box>
      </Typography>
      <Box pt={1.5}>
        <Typography variant="h5">
          {TEXT.SUBTITLE}
          {barcode}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <ChangeDelivererSelectingTemplate
      header={header}
      changeDelivererMachineRef={changeDelivererMachineRef}
    />
  );
};

ChangeDelivererSelecting.propTypes = {
  changeDelivererMachineRef: PropTypes.instanceOf(Interpreter).isRequired,
  barcode: PropTypes.string.isRequired
};

export default ChangeDelivererSelecting;
