import { useEffect, useContext } from 'react';
import { Hub } from 'aws-amplify';
import * as Sentry from '@sentry/browser';
import { CredentialsContext } from 'auth/login/credentials.context';
import showSnackbar from 'shared/snackbar';
import { useSnackbar } from 'notistack';
import HUB from './aws_amplify.constants';

function logError(payload) {
  const _code = payload?.data?.code;
  const _name = payload?.data?.name;
  const _event = payload?.event;
  const _message = payload?.message;

  const customError = new Error(`${HUB.TOKEN_FAILURE_MESSAGE.DATA}-${_code}`);

  Sentry.setExtras({
    code: _code,
    name: _name,
    event: _event,
    message: _message,
    file: 'aws_amplify.config.js'
  });

  Sentry.captureException(customError);
}

const TokenRefreshListen = ({ children }) => {
  const { setOpenDialogError } = useContext(CredentialsContext);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const listener = Hub.listen(HUB.AUTH_CHANNEL, data => {
      const { payload } = data;
      if (payload?.event === HUB.TOKEN_FAILURE_MESSAGE.EVENT) {
        if (payload?.data?.code === 'NotAuthorizedException') {
          showSnackbar({
            message: `Sua sessão expirou. E preciso fazer o login novamente. [${
              payload?.data?.code
            }]`,
            variant: 'error',
            enqueueSnackbar
          });
        } else {
          showSnackbar({
            message: `Ocorreu um erro ao tentar renovar o token de autenticação. [${
              payload?.data?.code
            }]`,
            variant: 'error',
            enqueueSnackbar
          });
          logError(payload);
        }
      }
    });

    return () => {
      Hub.remove(HUB.AUTH_CHANNEL, listener);
    };
  }, [enqueueSnackbar, setOpenDialogError]);

  return children;
};

export default TokenRefreshListen;
