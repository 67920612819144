import { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { SWITCHES } from 'view/constants';
import { getSelectedBase } from 'profile/profile.service';
import { CredentialsContext } from 'auth/login/credentials.context';
import { rules } from './access-control.rules';
import { getCompanyType, getRole } from './access-control.service';
import { IsFSActiveForCurrentBase } from './access-control.utils';

export const OptionsForDC = {
  IS_IN_WHATEVER_STATE: 0,
  IS_ABSENT: 1,
  IS_PRESENT: 2
};

Object.freeze(OptionsForDC);

function isAllowedByDCCapability(DCCapability, renderIfDenied, whenDC, LMCDC) {
  // eslint-disable-next-line camelcase
  const distributionCenter = LMCDC?.distribution_center;

  if (!distributionCenter) {
    return (
      whenDC === OptionsForDC.IS_IN_WHATEVER_STATE ||
      whenDC === OptionsForDC.IS_ABSENT
    );
  }
  if (!DCCapability) {
    return (
      whenDC === OptionsForDC.IS_IN_WHATEVER_STATE ||
      whenDC === OptionsForDC.IS_PRESENT
    );
  }

  const hasCapability = distributionCenter[DCCapability] || false;
  return renderIfDenied ? !hasCapability : hasCapability;
}

export function useGetSelectedRoleBase() {
  const [role, setRole] = useState(false);
  const { userSession } = useContext(CredentialsContext);

  useEffect(() => {
    (async () => {
      const LMCDC = getSelectedBase();

      const _role = await getRole(
        userSession,
        LMCDC?.last_mile_company?.identification || null, // eslint-disable-line camelcase
        LMCDC?.distribution_center?.id || 0 // eslint-disable-line camelcase
      );
      setRole(_role);
    })();
  }, [userSession]);

  return role;
}

async function isAllowedByUserRoles(
  userSession,
  actions,
  renderIfDenied,
  LMCDC
) {
  if (actions.length === 0) {
    // i.e.: render if allowed
    return !renderIfDenied;
  }

  const companyType = getCompanyType();
  if (companyType === null) {
    return renderIfDenied;
  }

  const role = await getRole(
    userSession,
    LMCDC?.last_mile_company?.identification || null, // eslint-disable-line camelcase
    LMCDC?.distribution_center?.id || 0 // eslint-disable-line camelcase
  );

  const rolePermissions = rules[companyType][role];
  if (!rolePermissions) {
    return renderIfDenied;
  }

  const permissionMapped = actions.map(action =>
    rolePermissions.includes(action)
  );
  const permitted = permissionMapped.some(permission => permission);
  return renderIfDenied ? !permitted : permitted;
}

export default function Can({
  excludeFS,
  includeFS,
  reverseCapability,
  DCCapability,
  whenDC,
  reverse,
  actions,
  children
}) {
  const [isAllow, setIsAllow] = useState(false);
  const { userSession } = useContext(CredentialsContext);

  let isFSActiveForThisDC = true;
  if (excludeFS) {
    isFSActiveForThisDC = !IsFSActiveForCurrentBase(excludeFS);
  } else if (includeFS) {
    isFSActiveForThisDC = IsFSActiveForCurrentBase(includeFS);
  }

  useEffect(() => {
    (async () => {
      const LMCDC = getSelectedBase();

      setIsAllow(
        isFSActiveForThisDC &&
          isAllowedByDCCapability(
            DCCapability,
            reverseCapability,
            whenDC,
            LMCDC
          ) &&
          (await isAllowedByUserRoles(userSession, actions, reverse, LMCDC))
      );
    })();
  }, [
    reverseCapability,
    DCCapability,
    whenDC,
    reverse,
    actions,
    isFSActiveForThisDC,
    userSession
  ]);

  if (isAllow) {
    return children;
  }
  return null;
}

export function CanFunction({ customDisabledTo, children }) {
  const fsLoggiPontoByIdentificationV2 = IsFSActiveForCurrentBase(
    SWITCHES.enableLoggiPontoByIdentificationV2
  );

  const fsLoggiPontoByLmcDcV2 = IsFSActiveForCurrentBase(
    SWITCHES.enableLoggiPontoByLmcDcV2
  );

  const isLoggiPonto = () => {
    const hasLoggiPontoOnLMCIdentification = () => {
      const LMCDC = getSelectedBase();

      const validIdentifications = ['loggi_ponto', 'loggi-ponto'];

      return validIdentifications.some(item =>
        // eslint-disable-next-line camelcase
        LMCDC?.last_mile_company?.identification.includes(item)
      );
    };

    const isBaseLoggiPonto =
      fsLoggiPontoByLmcDcV2 ||
      (fsLoggiPontoByIdentificationV2 && hasLoggiPontoOnLMCIdentification());

    return isBaseLoggiPonto;
  };

  if (customDisabledTo === 'loggiPonto') {
    if (isLoggiPonto()) {
      return false;
    }
  }

  // default is not should impact other roles
  return children || true;
}

CanFunction.propTypes = {
  children: PropTypes.node
};

CanFunction.defaultProps = {
  children: null
};

Can.propTypes = {
  includeFS: PropTypes.string,
  excludeFS: PropTypes.string,
  reverseCapability: PropTypes.bool,
  DCCapability: PropTypes.string,
  whenDC: PropTypes.oneOf([
    OptionsForDC.IS_IN_WHATEVER_STATE,
    OptionsForDC.IS_ABSENT,
    OptionsForDC.IS_PRESENT
  ]),
  reverse: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node.isRequired
};

Can.defaultProps = {
  includeFS: null,
  excludeFS: null,
  reverseCapability: false,
  DCCapability: null,
  whenDC: OptionsForDC.IS_IN_WHATEVER_STATE,
  reverse: false,
  actions: []
};
