import PropTypes from 'prop-types';
import React from 'react';
import { Box, Button, withStyles } from '@material-ui/core';
import { colors } from '@loggi/mar';
import { UPDATE_RESPONSIBLE_TEXTS } from './disputes.constants';

const StyledButton = withStyles({
  root: {
    '&.Mui-disabled': {
      backgroundColor: colors.smoke[300],
      color: 'white'
    }
  }
})(Button);

const ButtonUpdateAssignSelf = ({ handleSaveAssignSelf, driverSituation }) => (
  <Box mr={2} component="span">
    <StyledButton
      data-testid="button-update-assign-self-dispute-responsible"
      size="small"
      color="primary"
      variant="contained"
      onClick={handleSaveAssignSelf}
      disabled={!driverSituation}
    >
      {UPDATE_RESPONSIBLE_TEXTS.buttonWithoutResponsibleAssignSelf}
    </StyledButton>
  </Box>
);

ButtonUpdateAssignSelf.propTypes = {
  handleSaveAssignSelf: PropTypes.func.isRequired,
  driverSituation: PropTypes.bool.isRequired
};

export default ButtonUpdateAssignSelf;
