import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  /* TODO:
       This customization of the paper component should be removed
       when the theme customizes it accordingly */
  paper: {
    borderRadius: 10
  },
  skeleton: {
    borderRadius: typography.pxToRem(8)
  },
  gridMt: {
    marginTop: '40px'
  },
  discountChip: {
    backgroundColor: palette.error.light,
    color: palette.grey[900],
    height: spacing(3),
    margin: spacing(1),
    padding: spacing(0)
  },
  discountChipLabel: {
    fontSize: '12px',
    fontWeight: 700
  }
}));

export default useStyles;
