import React, { useCallback, useState, useContext, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  Button,
  Grid,
  Tooltip
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useParams } from 'react-router-dom';
import { SummaryContext } from 'tracking/template/summary.context';
import FilterComponent from 'shared/filter';
import InfoIcon from '@material-ui/icons/Info';
import Pagination, {
  usePagination,
  ITEMS_PER_PAGE_THIRTY
} from 'shared/pagination';
import SelectComponent from 'shared/select';
import { ORDER_BY, INTERNAL_LABEL } from 'shared/select/select.constants';
import { FILTERS } from 'shared/filter/filter.constants';
import ConfigurableTableError from 'shared/configurable-table/configurable-table-error.component';
import { FeatureSwitchContext } from 'infra/firebase/feature-switch-provider';
import PreviousListComponent from './previous-list/previous-list.component';
import { useStyles } from './finance.styles';
import {
  PERIOD_MAP,
  mountSubTitle,
  getExpectDate,
  TABLE_CONFIGURATION_V2,
  TABLE_CONFIGURATION_V3
} from './finance.configuration';
import {
  KEY_PERIOD,
  TEXT_ERROR,
  TEXT_TOOLTIP_OPEN,
  TEXT_TOOLTIP_CLOSE,
  TEXT_DOWNLOAD_REPORT,
  COLUMN_LABEL,
  CHARGE_TYPE
} from './finance.constants';
import ConfigurableTable from './configurable-table';

import financeService, {
  getAllPeriods,
  generateCsvLink
} from './finance.service';
import CardAggregationContainer from './card-aggregation';
import { TEXT_BTN } from './card-aggregation/card-aggregation.constants';
import cardAggService from './card-aggregation/card-aggregation.service';
import Drawer from './drawer';

function FinanceContainer() {
  const [dataResponse, setDataResponse] = useState([]);
  const [loadingSubTitle, setLoadingSubTitle] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [orderBy, setOrderBy] = useState(TABLE_CONFIGURATION_V3.orderBy[0]);
  const [tableConfiguration, setTableConfiguration] = useState(
    TABLE_CONFIGURATION_V3
  );
  const [error, setError] = useState(false);
  const [errorSubTitle, setErrorSubTitle] = useState(false);
  const [subTitle, setSubTitle] = useState('');
  const [filters, setFilters] = useState({});
  const [openDrawer, setOpenDrawer] = useState(false);
  const [valueNF, setValueNF] = useState({});
  const [currentPeriod, setCurrentPeriod] = useState('');
  const [count, setCount] = useState(0);
  const [allPeriods, setAllPeriods] = useState([]);
  const [downloadCsvLink, setDownloadCsvLink] = useState('');
  const [selectedPeriod, setSelectedPeriod] = useState({
    start: '',
    end: ''
  });
  const [chargeType, setChargeType] = useState([]);

  const [
    pagination,
    changePage,
    setNumberOfItems,
    resetPagination,
    setCurrentPage
  ] = usePagination();

  const classes = useStyles();

  const { setSummary } = useContext(SummaryContext);
  const { isLoading: isLoadingFS } = useContext(FeatureSwitchContext);

  const { period } = useParams();
  const content = PERIOD_MAP[period];

  const handleReset = useCallback(() => {
    resetPagination();
    setLoadingTable(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPreviousPeriodChange = item => {
    setSelectedPeriod(item);
    handleReset();
  };

  const handleOpenDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleChargeTypeFilters = response => {
    const chargeTypeFilters = [CHARGE_TYPE.DELIVERY_PAYMENT];
    if (response.pickupTotalValue) {
      chargeTypeFilters.push(CHARGE_TYPE.CUSTOMER_PICKUP_PAYMENT);
    }
    if (response.transhipmentTotalValue) {
      chargeTypeFilters.push(CHARGE_TYPE.TRANSHIPMENT_PAYMENT);
    }
    if (response.dropoffTotalValue) {
      chargeTypeFilters.push(CHARGE_TYPE.COUNTER_PICKUP_PAYMENT);
    }
    setChargeType(chargeTypeFilters);
  };

  useEffect(() => {
    setSummary(
      <Grid
        container
        direction="row"
        alignItems="center"
        display="flex"
        justify="space-between"
      >
        <Grid item xs>
          <Typography variant="h4">
            <strong>
              {content.title}
              {`  `}
            </strong>
            <Tooltip
              title={
                period === KEY_PERIOD.OPEN
                  ? TEXT_TOOLTIP_OPEN
                  : TEXT_TOOLTIP_CLOSE
              }
              placement="top"
              arrow
            >
              <InfoIcon fontSize="small" />
            </Tooltip>
          </Typography>
          <Grid container>
            {loadingSubTitle && (
              <Box pt={0.5} pb={0.5}>
                <Skeleton
                  variant="rect"
                  width="150px"
                  className={classes.skeleton}
                  height="35px"
                />
              </Box>
            )}
            {!loadingSubTitle && (
              <Typography variant="h4">
                {errorSubTitle ? '--' : subTitle}
              </Typography>
            )}
            <Box ml={2}>
              {period === KEY_PERIOD.PREVIOUS && (
                <PreviousListComponent
                  onPreviousPeriodChange={onPreviousPeriodChange}
                  selected={selectedPeriod}
                  allPeriods={allPeriods}
                />
              )}
            </Box>
          </Grid>
        </Grid>
        {period === KEY_PERIOD.CLOSE && (
          <Grid item align="center" xs={2}>
            <Button
              variant="outlined"
              color="default"
              size="medium"
              onClick={() => handleOpenDrawer()}
            >
              {TEXT_BTN}
            </Button>
          </Grid>
        )}
        <Grid item align="center">
          <Button
            variant="outlined"
            color="default"
            size="medium"
            href={downloadCsvLink}
          >
            {TEXT_DOWNLOAD_REPORT}
          </Button>
        </Grid>
      </Grid>
    );
    // eslint-disable-next-line
  }, [loadingSubTitle, subTitle, period, openDrawer]);

  const onError = () => {
    setError(true);
    setLoadingTable(false);
  };

  const onErrorSubTitle = () => {
    setErrorSubTitle(true);
    setLoadingSubTitle(false);
  };

  const onApplyChanges = items => {
    if (items.remove) {
      setFilters(current => {
        const currentState = current;
        delete currentState[items.remove];
        return { ...currentState };
      });
    } else {
      setFilters(currentState => {
        return { ...currentState, ...items };
      });
    }

    handleReset();
  };

  const onOrderChange = item => {
    setOrderBy(item);
    handleReset();
  };

  const changePagination = page => {
    changePage(page);
    setLoadingTable(true);
  };

  useEffect(() => {
    getAllPeriods()
      .then(dataPeriod => {
        setSelectedPeriod(getExpectDate(dataPeriod, period));
        setAllPeriods(dataPeriod.allIntervals);
      })
      .catch(() => {
        onErrorSubTitle();
        onError();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period]);

  useEffect(() => {
    setLoadingSubTitle(true);
    if (selectedPeriod.start !== '') {
      cardAggService(filters, selectedPeriod, period)
        .then(cleanData => {
          setLoadingSubTitle(false);
          setSubTitle(
            mountSubTitle(
              cleanData.startDate,
              cleanData.endDate,
              period,
              cleanData.periodTotalValue
            )
          );
          handleChargeTypeFilters(cleanData);
          setValueNF(cleanData);
        })
        .catch(onErrorSubTitle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.currentPage, filters, selectedPeriod]);

  useEffect(() => {
    if (currentPeriod !== period) {
      handleReset();
      setCurrentPeriod(period);
    } else if (loadingTable && selectedPeriod.start !== '') {
      financeService(
        selectedPeriod,
        pagination.currentPage,
        ITEMS_PER_PAGE_THIRTY,
        filters,
        orderBy.sortBy,
        orderBy.orderDirection
      )
        .then(cleanData => {
          setDataResponse(cleanData.packageCharges);
          setNumberOfItems(cleanData.totalItems);
          setCount(cleanData.totalItems);
          setError(false);
          setLoadingTable(false);
        })
        .catch(onError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    orderBy.sortBy,
    orderBy.orderDirection,
    pagination.currentPage,
    loadingTable,
    filters,
    selectedPeriod
  ]);

  useEffect(() => {
    (async () => {
      const csvLink = await generateCsvLink(selectedPeriod);
      setDownloadCsvLink(csvLink);
    })();
  }, [selectedPeriod]);

  useEffect(() => {
    if (!isLoadingFS) {
      setOrderBy(TABLE_CONFIGURATION_V2.orderBy[0]);
      setTableConfiguration(TABLE_CONFIGURATION_V2);
    }
  }, [isLoadingFS]);

  // if there is packages or some filter were appied, COULD have package.
  const hasPackages =
    count > 0 ||
    Object.values(filters).reduce((a, b) => a.concat(b), []).length > 0;

  return (
    <>
      {openDrawer && (
        <Drawer valueNF={valueNF} handleOpenDrawer={handleOpenDrawer} />
      )}
      <CardAggregationContainer
        filters={filters}
        selectedPeriod={selectedPeriod}
        onErrorContanier={error}
      />
      {!error && (
        <Paper
          className={classes.paper}
          component={Box}
          elevation={10}
          flexGrow={1}
          minHeight={44}
          p={5.5}
          marginBottom={3}
        >
          <Box mb={2} display="flex" justifyContent="space-between">
            <Box>
              <FilterComponent
                onApplyChanges={onApplyChanges}
                btnFilter={COLUMN_LABEL.PEOPLE}
                whichFilter={FILTERS.DELIVERER}
                titleArrayDeliverers={COLUMN_LABEL.PEOPLE}
              />
              {chargeType.length > 0 && (
                <FilterComponent
                  onApplyChanges={onApplyChanges}
                  btnFilter={COLUMN_LABEL.CHARGE_TYPE}
                  whichFilter={FILTERS.CHARGE_TYPE}
                  titleArrayDeliverers={COLUMN_LABEL.CHARGE_TYPE}
                  status={chargeType}
                />
              )}
            </Box>
            {!isLoadingFS && (
              <SelectComponent
                onSelectedChange={onOrderChange}
                selected={orderBy}
                selectOptions={tableConfiguration.orderBy}
                prefixLabel={ORDER_BY}
                internalOptionsLabel={INTERNAL_LABEL}
              />
            )}
          </Box>
          {!loadingTable && !isLoadingFS && (
            <ConfigurableTable
              data={dataResponse}
              columns={tableConfiguration.tableDisplay}
            />
          )}
          {(loadingTable || isLoadingFS) && (
            <>
              <Box mb={0.5}>
                <Skeleton
                  variant="rect"
                  width="100%"
                  className={classes.skeleton}
                  height="44px"
                />
              </Box>
              <Box mb={0.5}>
                <Skeleton
                  variant="rect"
                  width="100%"
                  className={classes.skeleton}
                  height="60px"
                />
              </Box>
              <Box mb={0.5}>
                <Skeleton
                  variant="rect"
                  width="100%"
                  className={classes.skeleton}
                  height="60px"
                />
              </Box>
              <Box mb={0.5}>
                <Skeleton
                  variant="rect"
                  width="100%"
                  className={classes.skeleton}
                  height="60px"
                />
              </Box>
            </>
          )}
        </Paper>
      )}
      {hasPackages > 0 && !error && (
        <Pagination
          onPageChange={changePagination}
          initialPage={pagination.currentPage}
          numberOfPages={pagination.numberOfPages}
          setCurrentPage={setCurrentPage}
        />
      )}
      {!loadingTable && !isLoadingFS && error && (
        <ConfigurableTableError TEXT_TABLE={TEXT_ERROR} />
      )}
    </>
  );
}

export default FinanceContainer;
