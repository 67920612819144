export const KEY_PERIOD = {
  CLOSE: 'fechado',
  OPEN: 'aberto',
  PREVIOUS: 'anteriores'
};

export const COLUMN_LABEL = {
  EFFECTIVE_DATE_TIME: 'Lançamento',
  PEOPLE: 'Pessoas',
  REGION_NAME: 'Tipo de região',
  SIZE_TYPE: 'Pacote Pesado',
  CHARGE_TYPE: 'Serviço',
  VALUE: 'Valor final',
  DEDUCTION: 'Desconto'
};

export const SIZE_TYPE = {
  LARGE: 'SIZE_TYPE_LARGE'
};

export const CHARGE_TYPE = {
  DELIVERY_PAYMENT: 'CHARGE_TYPE_DELIVERY_PAYMENT',
  TRANSHIPMENT_PAYMENT: 'CHARGE_TYPE_TRANSHIPMENT_PAYMENT',
  COUNTER_PICKUP_PAYMENT: 'CHARGE_TYPE_COUNTER_PICKUP_PAYMENT',
  CUSTOMER_PICKUP_PAYMENT: 'CHARGE_TYPE_CUSTOMER_PICKUP_PAYMENT'
};

export const CHARGE_TYPE_LABEL = {
  DELIVERY_PAYMENT: 'Entrega',
  TRANSHIPMENT_PAYMENT: 'Processamento',
  COUNTER_PICKUP_PAYMENT: 'Balcão',
  CUSTOMER_PICKUP_PAYMENT: 'Coleta',
  SERVICE_KIND_DROPOFF: 'Dropoff'
};

export const CHARGE_TYPE_LABEL_PAST = {
  DELIVERED: 'Entregou',
  PICKED_UP: 'Coletou',
  TRANSHIPPED: 'Processou'
};

export const FORMAT = {
  DD_MMM: 'DD MMM',
  DD_MMM_HOURS: 'DD MMM[,] HH:mm',
  DD_MMM_YYYY: 'DD MMM[,] YYYY'
};

export const TEXT_ERROR = {
  MSG_ERROR: 'Eita! Deu algo ruim aqui e não conseguimos carregar os valores.',
  BTN_ERROR: 'Carregar novamente'
};

export const TEXT_TOOLTIP_OPEN =
  'Esse é o valor em aberto até agora e pode ter alterações.';
export const TEXT_TOOLTIP_CLOSE =
  'Valor calculado com base nos serviços realizados no período e pode ter descontos no pagamento.';
export const TEXT_DOWNLOAD_REPORT = 'Baixar relatório';
export const PREVIOUS_PERIODS = 'Períodos Anteriores';

export default KEY_PERIOD;

export const DEDUCTIONS_TYPES = {
  DELAYED: 'TYPE_COMPLETED_DELAYED',
  DISPUTED: 'TYPE_COMPLETED_DISPUTED'
};

export const TEXT_DELAYED = 'Falta';
export const TEXT_DISCOUNT = 'Atraso';
