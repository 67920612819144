import React from 'react';
import { Grid, Typography, Divider } from '@material-ui/core';

import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import { formatNumber } from 'shared/formatters';
import {
  LABEL_PKG,
  LABEL_TRANSHIPMENT,
  LABEL_COUNTER,
  LABEL_PICKUP
} from './card-aggregation.constants';
import { formatValue } from '../finance.configuration';

const CardAggregation = ({
  localTotalValue,
  atendidaTotalValue,
  pickupTotalValue,
  transhipmentTotalValue,
  dropoffTotalValue,
  periodTotalAmount,
  loading,
  periodTotalValue
}) => {
  const loadingSkeleton = () => {
    return (
      <Grid container spacing={10}>
        <Grid item>
          <Skeleton variant="rect" height={32} width={42} />
          <br />
          <Skeleton variant="rect" height={19} width={100} />
        </Grid>
        <Grid item>
          <Skeleton variant="rect" height={32} width={42} />
          <br />
          <Skeleton variant="rect" height={19} width={100} />
        </Grid>
        <Grid item xs={1}>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item>
          <Skeleton variant="rect" height={32} width={42} />
          <br />
          <Skeleton variant="rect" height={19} width={100} />
        </Grid>
        <Grid item>
          <Skeleton variant="rect" height={32} width={42} />
          <br />
          <Skeleton variant="rect" height={19} width={100} />
        </Grid>
        <Grid item xs={1}>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item>
          <Skeleton variant="rect" height={32} width={42} />
          <br />
          <Skeleton variant="rect" height={19} width={100} />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {loading && loadingSkeleton()}
      {!loading && (
        <Grid
          container
          spacing={6}
          display="flex"
          justify="space-between"
          data-testid="card-aggregation-content"
          wrap="nowrap"
        >
          <Grid
            xs={4}
            container
            item
            spacing={5}
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item container spacing={10} alignItems="center" wrap="nowrap">
              <Grid item>
                <Typography>
                  <strong>{formatValue(periodTotalValue)}</strong>
                </Typography>
                <Typography color="textSecondary" variant="body2" noWrap>
                  <em>Total estimado</em>
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  <strong>{formatNumber(periodTotalAmount)}</strong>
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  <em>{LABEL_PKG}</em>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <Divider orientation="vertical" />
          </Grid>

          <Grid xs={8} container item spacing={5} alignItems="center">
            <Grid item container spacing={10} alignItems="center" wrap="nowrap">
              <Grid item>
                <Typography>
                  <strong>
                    {formatValue(localTotalValue + atendidaTotalValue)}
                  </strong>
                </Typography>
                <Typography color="textSecondary" variant="body2" noWrap>
                  <em>Em entregas</em>
                </Typography>
              </Grid>
              {pickupTotalValue > 0 && (
                <Grid item>
                  <Typography>
                    <strong>{formatValue(pickupTotalValue)}</strong>
                  </Typography>
                  <Typography color="textSecondary" variant="body2" noWrap>
                    <em>{LABEL_PICKUP}</em>
                  </Typography>
                </Grid>
              )}
              {transhipmentTotalValue > 0 && (
                <Grid item>
                  <Typography>
                    <strong>{formatValue(transhipmentTotalValue)}</strong>
                  </Typography>
                  <Typography color="textSecondary" variant="body2" noWrap>
                    <em>{LABEL_TRANSHIPMENT}</em>
                  </Typography>
                </Grid>
              )}
              {dropoffTotalValue > 0 && (
                <Grid item>
                  <Typography>
                    <strong>{formatValue(dropoffTotalValue)}</strong>
                  </Typography>
                  <Typography color="textSecondary" variant="body2" noWrap>
                    <em>{LABEL_COUNTER}</em>
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

CardAggregation.propTypes = {
  localTotalValue: PropTypes.number,
  atendidaTotalValue: PropTypes.number,
  pickupTotalValue: PropTypes.number,
  transhipmentTotalValue: PropTypes.number,
  dropoffTotalValue: PropTypes.number,
  periodTotalAmount: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  periodTotalValue: PropTypes.number
};

CardAggregation.defaultProps = {
  localTotalValue: 0.0,
  atendidaTotalValue: 0.0,
  pickupTotalValue: 0.0,
  transhipmentTotalValue: 0.0,
  dropoffTotalValue: 0.0,
  periodTotalAmount: '0',
  periodTotalValue: 0.0
};

export default CardAggregation;
